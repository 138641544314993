<template>
            <Card>
                <template #title>
                    <Toolbar>
                        <template #start>
                            <h3>
                                {{ $t('players_ava') }}
                            </h3>
                        </template>
                        <template #end>
                            <Button :label="$t('create_new_ava')" icon="pi pi-plus" class="p-button-sm p-button-success" 
                            @click="$router.push('/avatars/create')" />
                        </template>
                    </Toolbar>
                </template>
                <template #content>
                    <ProgressBar v-if="loadingFlag" mode="indeterminate" style="height: .5em" />
                    <DataView v-else :value="avatars" :layout="layout" :paginator="true" :rows="10">
                        <template #list="{data, index}">
                            <div class="col-12 col">
                                <div class="product-list-item">
                                    <!-- <img :src="data.preview" :alt="data.name"/> -->
                                    <div class="product-image">
                                        <!-- :src="require(`https://minio.takemybet.pro/avatars/${data.preview}.jpeg`)" -->
                                        <!-- https://minio.takemybet.pro/mini-avatars/mini-avatars/mini_ -->
                                        <ImagePreview 
                                        ref="imagePreview"
                                         @show="toShowAva(data)"
                                          @hide="toHideAva(data)"
                                          @load="getImageSize(index)"
                                           :src="holdPreview ? `https://minio.takemybet.pro/avatars/${data.image}` : `https://minio.takemybet.pro/avatars/${data.preview}`"
                                            :alt="data.preview" preview />
                                            <span 
                                            v-if="imagesParams[index] && 
                                            imagesParams[index].width && 
                                            imagesParams[index].height"
                                            class="image-size"
                                            >
                                                {{`${imagesParams[index].width}×${imagesParams[index].height}px`  }}

                                            </span> 
                                    </div>
                                    <div class="product-list-detail">
                                        <div class="product-name">{{data.name}}</div>
                                        <div class="product-description"> {{data.descr}}</div>
                                        <div class="field-radiobutton _no-icon d-flex align-center">
                                            <Checkbox id="type" name="is_default" 
                                            @change="changeIsStarted(data)" 
                                            v-model="data.is_default"
                                            :binary="true"
                                            :disabled="data.is_default || data.loadingItem"
                                            />
                                            <label for="is_default" class="ml-1">
                                                {{$t('started_ava')}}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="product-list-action">
                                        <span class="product-price">{{ $t('user_ava_count') }}: {{data.num_of_users }}</span>
                                        <span class="p-buttonset">
                                            <Button @click="toEditAva(data)" :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" :label="$t('edit')"
                                              icon="pi pi-pencil" class="p-button-sm p-button-warning" />
                                            <Button @click="toDeleteAva(index)" 
                                            :disabled="loadingFlag || data.loadingItem" :loading="loadingFlag || data.loadingItem" 
                                            :label="$t('delete')"
                                            aria:haspopup="true" :aria-controls="`overlay_panel_${index}`"
                                              icon="pi pi-trash" class="p-button-sm p-button-danger" />
                                          </span>
                                          <!-- class="admin-overlay" -->
                                          <OverlayPanel :ref="`op_${index}`" appendTo="body" :id="`overlay_panel_${index}`" class="p-confirm-popup">
                                            <div class="p-confirm-popup-content">
                                              <i class="p-confirm-popup-icon pi pi-exclamation-triangle"></i>
                                              <div class="p-confirm-popup-message d-flex flex-column" 
                            style="max-width:400px"
                            >
                                <h3>{{ $t('attention_title') }}</h3>
                                <span>{{ $t('avatar_will_deleted') }}</span>
                                <h4 style="margin-top: 5px;">{{ $t('attention_question') }}</h4>
                            </div>
                                            </div>
                                            <div class="p-confirm-popup-footer">
                                                <span class="p-buttonset">
                                                    <Button @click="toCloseOverlay(index)" :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                    :label="$t('no')"
                                                     class="p-button-sm p-button-warning" />
                                                    <Button @click="toConfirmDelete(data, index)" 
                                                    :disabled="data.loadingItem" :loading="data.loadingItem" 
                                                    :label="$t('confirm')"
                                                      class="p-button-sm p-button-danger" />
                                                  </span>
                                            </div>
                                          </OverlayPanel>
                                    </div>
                                </div>
                            </div>
                        </template>
                        </DataView>
                </template>
            </Card>
</template>

<script>
import {mapGetters} from 'vuex';
    export default {
        name: 'ViewPlayerAvatars',
        data() {
            return {
                loadingFlag: false,
                layout: 'list',
                holdPreview: '',
                // avatars:[
                //     {
                //         preview: 'https://minio.takemybet.pro/avatars/player_avatar_0.jpeg',
                //         full_img: 'https://minio.takemybet.pro/avatars/player_avatar_0.jpeg',
                //         name: this.$t('name'),
                //         descr: this.$t('cat'),
                //         user_ava_count: 1234,
                //         isStarted: 'start_ava',
                //     },
                //     {
                //         preview: 'https://minio.takemybet.pro/mini-avatars/mini-avatars/mini_player_avatar_1.jpeg',
                //         full_img: 'https://minio.takemybet.pro/avatars/player_avatar_1.jpeg',
                //         name: this.$t('name'),
                //         descr: this.$t('cat'),
                //         user_ava_count: 344,
                //         isStarted: 'no_start_ava',
                //     },
                // ],
                imagesParams:{},
            }
        },
        watch: {
        },    
            async mounted() {
            // await this.getImageInfo();
            if (this.avatars.length) {
                return;
            }
            this.getAvatars();
        },
        computed: {
            ...mapGetters({
                avatars: 'playerAvatars/getAvatars',
            }),
        },
        methods: {
            async getAvatars() {
                this.loadingFlag = true;
                try {
                await this.$store.dispatch('playerAvatars/getAvatars');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
            } finally{
                this.loadingFlag = false;
            }
            },
            async getImageInfo() {
                for (const ava of this.avatars){
                const img = new Image();
                const avaPreview = ava.preview;
                    console.log('ava', avaPreview);
                    img.src = ava.preview;
                     await new Promise((resolve, reject) => {
                       img.onload = resolve;
                       img.onerror = reject;
                     });
                    //  console.log('img', img.weight);
                    const size = `${img.width}x${img.height}`; 
                    // console.log('size', size);
                    const weight = `${Math.round(img.src.length / 1024)} KB`; 
                        ava.size = size;
                        ava.weight = weight;
            }
            },
            getImageSize(index) {
      const image = this.$refs.imagePreview[index];
      if (image) {
        this.$nextTick(() => {
          if (image.complete && image.naturalWidth && image.naturalHeight) {
            this.$set(this.imagesParams, index, {
              width: image.naturalWidth,
              height: image.naturalHeight
            });
          }
        });
      }
    },
            toShowAva(ava){
                this.holdPreview = ava.preview;
                ava.preview = ava.full_img;
            },
            toHideAva(ava){
                ava.preview = this.holdPreview;
                this.holdPreview = '';
            },
            async changeIsStarted(ava){
                this.avatars.forEach(el => {
                    el.loadingItem = true;
                });
                const id = ava.id;
                try {
                    await this.$store.dispatch('playerAvatars/editDefaultAvatar', id);
                    this.avatars.forEach(el => {
                    el.is_default = false;
                });
                ava.is_default = true;
                    this.$toast.add({ severity: 'success', summary: this.$t('default_ava_changed'), life: 4000 });
                    // await this.getAvatars();
                } catch (error) {
                    console.log('eerror', error);
                    this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
                } finally{
                    this.avatars.forEach(el => {
                        el.loadingItem = false;
                    });
                }
            },
            toEditAva(ava){
                this.$router.push(`/avatars/edit/${ava.id}`);
                
            },
            toDeleteAva(index){
                this.$refs[`op_${index}`].toggle(event);
            },
            async toConfirmDelete(ava, index){
                ava.loadingItem = true;
                try {
                    // console.log('index', index);
                    await this.$store.dispatch('playerAvatars/deleteAvatar', ava.id);
                    // this.avatars.splice(index, 1);
                    this.avatars = this.avatars.filter(item => item.id !== ava.id);
                    this.$toast.add({ severity: 'success', summary: this.$t('ava_deleted'), life: 4000 });
                    // await this.getAvatars();
                } catch (error) {
                    if (error.response.data.detail === 'Can\'t delete the default avatar.'){ 
                        this.$toast.add({ severity: 'error', summary: this.$t('cant_delete_default_avatar'), life: 4000 });
                    } else{

                        this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
                    }
                } finally{
                    this.$refs[`op_${index}`].toggle(event);
                    ava.loadingItem = false;
                }
            },
            toCloseOverlay(index){
                this.$refs[`op_${index}`].hide(event);
            }

        },
        beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('setPageName', 'players_ava')
    });
  },
    }
</script>

<style lang="scss" scoped>
.p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.product-name {
	font-size: 1.5rem;
	font-weight: 700;
}

.product-description {
	margin: 0 0 0.25rem 0;
}

.product-category-icon {
	vertical-align: middle;
	margin-right: .5rem;
}

.product-category {
	font-weight: 600;
	vertical-align: middle;
}

:deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 0.25rem;
	width: 100%;
    .product-image{
        display: flex;
        justify-content:center;align-items: center;
        position: relative;
        width: 75px;
        flex: 0 0 75px;
        height: 75px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        span{
            flex: 0 0 75px;
            display: flex;
        }
    }
    .image-size{
        pointer-events: none;
        position: absolute;
        display: flex;
        justify-content:center;align-items: center;
        bottom: -10px;
        height: 20px;
        left: 0px;
        background-color: var(--pink-400);
        color: var(--white);
        padding: 1px;
        border-radius: 4px;
    }
    .p-image{
        
        max-width: 100%;
        max-height: 100%;
    }
	img {
        max-width: 100%;
        max-height: 100%;
	}
    
	.product-list-detail {
        margin-left: 1rem;
        text-align: left;
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}
    
	.product-list-action {
        align-items: flex-end;
		display: flex;
		flex-direction: column;

	}

	.p-button {
		margin-bottom: .5rem;
	}
}

:deep(.product-grid-item) {
	margin: .5rem;
	border: 1px solid #dee2e6;

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		width: 75%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;

		img {
			width: 75%;
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
}
</style>